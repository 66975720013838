'use client';

import { ApolloLink, HttpLink } from '@apollo/client';
import {
  ApolloNextAppProvider,
  NextSSRInMemoryCache,
  NextSSRApolloClient,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr';
import { createApolloErrorLink, setAppApolloClientContextLink } from 'Common/functions/ApolloClient';
import { createUploadLink } from 'apollo-upload-client';

// have a function to create a client for you
function makeClient() {
  const uploadLink = createUploadLink({
    uri: process.env.NEXT_PUBLIC_V2_GATEWAY_ENDPOINT,
    credentials: 'include',
    fetchOptions: { cache: 'no-store' },
  });

  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_V2_GATEWAY_ENDPOINT,
    credentials: 'include',
    // you can disable result caching here if you want to
    // (this does not work if you are rendering your page with `export const dynamic = "force-static"`)
    fetchOptions: { cache: 'no-store' },
    // you can override the default `fetchOptions` on a per query basis
    // via the `context` property on the options passed as a second argument
    // to an Apollo Client data fetching hook, e.g.:
    // const { data } = useSuspenseQuery(MY_QUERY, { context: { fetchOptions: { cache: "force-cache" }}});
  });

  return new NextSSRApolloClient({
    // use the `NextSSRInMemoryCache`, not the normal `InMemoryCache`
    cache: new NextSSRInMemoryCache(),
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([
          // in a SSR environment, if you use multipart features like
          // @defer, you need to decide how to handle these.
          // This strips all interfaces with a `@defer` directive from your queries.
          new SSRMultipartLink({
            stripDefer: true,
          }),
          createApolloErrorLink().concat(httpLink),
        ])
        : createApolloErrorLink()
          .concat(setAppApolloClientContextLink)
          .concat(uploadLink),
  });
}

// you need to create a component to wrap your app in
export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );
}
